const pattern = new RegExp(/^\/[a-z]{2}-([a-z]{2})\/?/)

export function useUrlBuilder() {
  function slugToPagePath (slug: string) {
    if (!slug) return ''
    return slug.replace(/__/g, '/').replace(/^\/+/, '')
  }

  function resolveLocalePath (to: string): string {
    to = (to || '').replace(/\/+$/, "")
    
    const { $i18n } = useNuxtApp()

    if(to.startsWith('https://') || to.startsWith('http://')) return to

    // If the path is already locale prefixed, return
    if (pattern.test(to)) {
      return to
    }

    // Construct localised path removing any leading or trailing slashes from the 'to' param
    return ('/' + $i18n.locale.value + '/' + to.replace(/^\/+/, '')).replace(/\/+$/, "")
  }

  interface Item {
    linkText: string
    internalLink: {
      slug: string
      _modelApiKey: string | undefined
      category: {
        slug: string | undefined
      }
    }
    internalLinkQueryParams: string | undefined
    modalSidebar: {
      id: string
      slug: string
      type: string | undefined
    }
  }

  function buildInternalLink(item: Item) {
    const linkQueryParams = item?.internalLinkQueryParams ? item.internalLinkQueryParams : ''
    let pathPrefix = ''

    if (!item?.internalLink) {
      if (!item?.modalSidebar) {
        return null
      }

      return '?modal=' + item.modalSidebar.slug
    }

    switch (item.internalLink._modelApiKey) {
      case 'composite_pdp':
        pathPrefix = 'shop/'
        break
      case 'article_category':
        pathPrefix = 'explore/'
        break
      case 'article':
        pathPrefix = 'explore/' + item.internalLink.category?.slug + '/'
        break
      default:
        pathPrefix = ''
    }

    return resolveLocalePath(
      pathPrefix + slugToPagePath(item.internalLink.slug)
    ) + linkQueryParams
  }

  function isLinkOrOpenUI(item: Item) {
    if (item?.internalLink) return {
      isLink: true,
      opensUI: false,
      internalLink: buildInternalLink(item)
    }
    else if (item?.modalSidebar?.slug) return {isLink: false, opensUI: true, internalLink: ''}

    return {isLink: false, opensUI: false, internalLink: ''}
  }

  function openUIElement(item: Item) {
    const slug = item?.modalSidebar?.slug
    if (slug) {
      switch (item.modalSidebar?.type) {
        case 'sidebar_page': {
          const sidebarStore = useSidebarStore()
          sidebarStore.setActiveSidebar(slug)
          break
        }
        case 'dialog': {
          const modalStore = useModalStore()
          modalStore.OPEN_MODAL_PAGE({ slug })
          break
        }
      }
    } else console.error('Cannot open modal or sidebar with no slug')
  }

  return { resolveLocalePath, slugToPagePath, buildInternalLink, isLinkOrOpenUI, openUIElement }
}
